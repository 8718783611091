import Vue from 'vue'
import Vuex from 'vuex'
import services from '@/helpers/services.js'
// import { notification } from '@/helpers/custom.js'

Vue.use(Vuex)
const idToken = window.localStorage.getItem('idToken')
const userId = window.localStorage.getItem('userId')
const role = window.localStorage.getItem('role')
const user = window.localStorage.getItem('user')
const localOrderList = window.localStorage.getItem('localOrderList')
const localselectOrderDetail = window.localStorage.getItem('localselectOrderDetail')
const localBeforeOrderList = window.localStorage.getItem('localBeforeOrderList')

export default new Vuex.Store({
  state: {
    idToken: idToken ? JSON.parse(idToken) : null,
    userId: userId ? JSON.parse(userId) : null,
    role: role ? JSON.parse(role) : [],
    userInfo: user ? JSON.parse(user) : null,
    OrderList: localOrderList ? JSON.parse(localOrderList) : [],
    selectOrderDetail: localselectOrderDetail ? JSON.parse(localselectOrderDetail) : null,
    beforeOrderList: localBeforeOrderList ? JSON.parse(localBeforeOrderList) : []
  },
  getters: {
    userGetter (state) {
      return state.userInfo
    },
    idToken (state) {
      return state.idToken
    },
    getterOrderList (state) {
      return state.OrderList
    },
    getterBeforeOrderList (state) {
      return state.beforeOrderList
    },
    getterSelectOrderDetail (state) {
      return state.selectOrderDetail
    }
  },
  mutations: {
    authUser (state, userData) {
      state.idToken = userData.token
      state.user = userData.user
      window.localStorage.setItem('idToken', JSON.stringify(state.idToken))
      window.localStorage.setItem('user', JSON.stringify(state.user))
    },
    addRole (state, roleData) {
      state.role = roleData
      window.localStorage.setItem('role', JSON.stringify(state.role))
    },
    addUserInfo (state, userData) {
      state.userInfo = userData
      window.localStorage.setItem('user', JSON.stringify(state.userInfo))
    },
    addOrderListAdd (state, orders) {
      state.OrderList = orders
      window.localStorage.setItem('localOrderList', JSON.stringify(state.OrderList))
    },
    addBeforeOrderListAdd (state, orders) {
      state.beforeOrderList = orders
      window.localStorage.setItem('localBeforeOrderList', JSON.stringify(state.beforeOrderList))
    },
    addOrderDetail (state, data) {
      state.selectOrderDetail = data
      window.localStorage.setItem('localselectOrderDetail', JSON.stringify(state.selectOrderDetail))
    }
  },
  actions: {
    addOrderDetail ({ commit }, response) {
      commit('addOrderDetail', response)
    },
    addOrderData ({ commit }, response) {
      commit('addOrderListAdd', response)
    },
    beforeOrderData ({ commit }, response) {
      commit('addBeforeOrderListAdd', response)
    },
    authUserLoginPage ({ commit }, response) {
      commit('authUser', response)
    },
    addRoleLoginPage ({ commit }, response) {
      commit('addRole', response)
    },
    userAdd ({ commit }, response) {
      commit('addUserInfo', response)
    },
    signin ({ commit }, authData) {
      return new Promise((resolve, reject) => {
        services.login(authData)
          .then((response) => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    }
  },
  modules: {
  }
})
