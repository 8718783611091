<template>
  <el-container class="schedule_detail m-t-60">
    <el-header class="my-header" style="font-size: 12px">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="4">
          <el-button type="text" class="header_back_button" icon="el-icon-arrow-left" @click="backPage()">Буцах
          </el-button>
        </el-col>
        <el-col :span="16">
          <span class="header_center_title">Захиалгын дэлгэрэнгүй</span>
        </el-col>
      </el-row>
    </el-header>
    <el-row type="flex" class="row-bg m-b-100" justify="center">
      <el-col :span="24" class="home_body">
        <el-card shadow="always" :body-style="{ padding: '10px' }" style="border-radius:0px;line-height:2">
          <el-row v-if="selectedOrder">
            <el-col :span="24" :offset="1">
              <span class="standart_text">Захиалгын дугаар: </span>{{selectedOrder.id}} <br />
              <span class="standart_text">Утасны дугаар: </span><a
                :href="'tel:' +selectedOrder.customer_phone">{{ selectedOrder.customer_phone }}</a> <br />
              <span class="standart_text">Захиалсан огноо:</span> {{dateFormat(selectedOrder.created_at)}} <br />
              <span class="standart_text">Төлбөр:</span>
              <el-tag size="mini" v-if="selectedOrder.is_paid == 1" effect="dark">Төлсөн</el-tag>
              <el-tag size="mini" effect="dark" v-else-if="selectedOrder.is_paid == 0">Төлөгдөөгүй</el-tag> <br />
              <span class="standart_text"> Хаяг: </span>
              <br />{{selectedOrder.address.city}} хот - {{selectedOrder.address.district}} дүүрэг -
              {{selectedOrder.address.horoo}} - {{selectedOrder.address.bairname}} - {{selectedOrder.address.bairnote}}
              <br />
              <span class="standart_text"> Хаягийн линк: </span> <br />
              <span v-if="selectedOrder.address.map_link"> <a :href="selectedOrder.address.map_link"
                  target="_blank">{{selectedOrder.address.map_link}}</a></span> <br />
              <el-input class="m-t-10" type="textarea" style="width: 70%;" :rows="3"
                placeholder="Та google map-аас copy хийж дараагийн хүн олоход туслаарай :)" v-model="map_link">
              </el-input>
              <el-button class="m-l-20" type="success" size="mini" icon="el-icon-check" circle
                @click="saveAddressMapLink()"></el-button> <br />
              <!-- {{ birthday.getDay() }} -->
              <!-- {{ birthday.getHours() }} :  {{ birthday.getMinutes() }} : {{ birthday.getSeconds() }} -->
              <span class="standart_text"> Захиалгын тайлбар линк: </span> <br />
              <span v-if="selectedOrder">
                <span>{{selectedOrder.order_description}}</span>
              </span> <br />
              <el-input class="m-t-10" type="textarea" style="width: 70%;" :rows="3"
                placeholder="Та энэ захиалга дээр тэмдэглэл өөрчлөх бол бичээрэй." v-model="order_desc">
              </el-input>
              <el-button class="m-l-20" type="success" size="mini" icon="el-icon-check" circle
                @click="saveOrderDescription()"></el-button>
            </el-col>
          </el-row>
          <div class="schedule_body_info_students m-t-20 m-b-20 m-l-10">
            <span><span class="standart_text">Захиалсан бүтээгдэхүүн</span></span>
            <el-card v-for="(product, index) in selectedOrder.detail" :key="index" class="m-t-10">
              <div>
                <el-row style="height:30px">
                  <el-col :span="20">
                    {{index+1 }}.
                    {{product.product_name}} - {{product.item_count}} ш<br />
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </div>
          <el-button type="primary" class="w-100" @click="orderStatusChange('70')">Хүргэлтэнд гарах</el-button><br />
          <el-button type="warning" class="w-100 m-t-10" @click="changeDriver()">Жолооч шилжүүлэх</el-button><br />
          <el-button type="success" class="w-100 m-t-10" @click="orderStatusChange('100')">Амжилттай хүргэсэн
          </el-button>
        </el-card>
      </el-col>
    </el-row>
    <el-drawer direction="btt" title="Бусад жолооч" custom-class="demo-drawer" :visible.sync="driverChangeDialog"
      size="40%" :before-close="handleCloseDriverChangeDialog">
      <div class="p-20">
        <el-select class="w-100" v-model="changeDriverId" placeholder="Шилжүүлэх жолооч сонгох">
          <el-option v-for="item, index in this.employeeList" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <div class="p-20">
        <el-button @click="handleCloseDriverChangeDialog()">Буцах</el-button>
        <el-button type="warning" @click="changeDriverFunction()">Шилжүүлэх</el-button>
      </div>
      </div>

    </el-drawer>
    <!-- <StudentDetailDialog v-if="this.$root.studentDetailDialogVariable" :selectedStudent="selectedStudentData"/> -->
  </el-container>
</template>

<script>
import {
  notification
} from '@/helpers/custom'
import services from '@/helpers/services.js'
// import StudentDetailDialog from '@/views/studentDetailDialog/studentDetailDialog.vue'
import {
  mapGetters
} from 'vuex'

export default {
  components: {
    // StudentDetailDialog
  },
  computed: {
    ...mapGetters({
      selectedOrder: 'getterSelectOrderDetail',
      userGetter: 'userGetter'
    })
  },
  data () {
    return {
      // user: JSON.parse(localStorage.getItem('user')),
      birthday: new Date(),
      now: Date.now(),
      loading: true,
      map_link: '',
      order_desc: '',
      driverChangeDialog: false,
      employeeList: [],
      changeDriverId: null
    }
  },
  created () {
    this.order_desc = this.selectedOrder.order_description
    this.getEmmloyeeList()
  },
  methods: {
    changeDriverFunction () {
      const payload = {
        order_id: this.selectedOrder.id,
        worker_key: this.changeDriverId
      }
      services.connectOrderDrive(payload)
        .then((res) => {
          notification('success_empty')
          this.location.reload()
          console.log(res, 'rees')
        }).catch((err) => {
          console.log(err)
        })
    },
    getEmmloyeeList () {
      const payload = {
        company_id: this.companyInfoGetter.id
      }
      services.getEmployeeList(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.employeeList = response.result
          }
        })
    },
    handleCloseDriverChangeDialog () {
      this.driverChangeDialog = false
    },
    changeDriver () {
      this.driverChangeDialog = true
    },
    saveOrderDescription () {
      this.$root.fullscreenLoading = true
      const payload = {
        order_id: this.selectedOrder.id,
        order_description: this.order_desc
      }
      services.saveOrderDescription(payload)
        .then((res) => {
          notification('success_empty')
          this.$root.fullscreenLoading = false
          console.log(res, 'rees')
        }).catch((err) => {
          console.log(err)
        })
    },
    saveAddressMapLink () {
      this.$root.fullscreenLoading = true
      const payload = {
        address_id: this.selectedOrder.address.id,
        map_link: this.map_link
      }
      services.addressLinkSave(payload)
        .then((res) => {
          notification('success_empty')
          this.$root.fullscreenLoading = false
          console.log(res, 'rees')
        }).catch((err) => {
          console.log(err)
        })
    },
    orderStatusChange (status) {
      this.$confirm('Захиалга амжилтта болгох уу?', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'success'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          order_id: this.selectedOrder.id,
          order_status: status
        }
        services.orderUpdateStatus(payload)
          .then((res) => {
            this.$router.push({
              path: '/'
            })
            this.$root.fullscreenLoading = false
            console.log(res, 'rees')
          }).catch((err) => {
            console.log(err)
          })
      }).catch(() => {})
    },
    dateFormat (data) {
      return data
    },
    checkCountFunction (data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('student_note') === true) {
        if (data.student_note.length === 0) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    callUserInfoFunction (data) {
      this.selectedStudentData = data
      this.$root.studentDetailDialogVariable = true
    },
    backPage () {
      this.$router.push({
        path: '/'
      })
    },
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    },
    getScheduleLogFind () {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_id: this.selectedSchedule.id
      }
      services.getScheduleLogById(payload)
        .then((response) => {
          this.scheduleLogDetail = response.data
          this.$root.fullscreenLoading = false
          this.loading = false
        })
    }
  }
}

</script>

<style>

</style>
