<template>
  <el-container class="home">
    <el-header class="my-header" style="font-size: 14px">
      <span class="m-l-10">Нүүр</span>
      <div class="flr">
        <span class="m-r-10" prob="first_name">{{user.name}}</span>
      </div>
    </el-header>
    <el-row class="m-t-60">
      <el-col :span="22" :offset="1">
        <el-row :gutter="20" type="flex" class="row-bg m-t-10" justify="space-around">
              <el-col :span="12">
                  <div class="schedule_detail_count p-10">
                  Нийт хувиарлагдсан <br/>
                  <span style="color:#281C9D">{{ parseInt(orderList.length) + parseInt(beforeOrder.length) }}</span>
                  </div>
              </el-col>
                  <el-col :span="12">
                  <div class="schedule_detail_count">
                  Нийт хүргэсэн<br/>
                  <span style="color:green">{{checkSuccessOrderCount()}}</span>
                  </div>
              </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="m-t-10">
        <el-col :span="22" :offset="1"><el-row type="flex" class="row-bg m-t-10" justify="space-around">
          <el-col :span="4" v-for="orderItem in this.groupByProductName()" :key="orderItem.id">
            <div class="schedule_detail_count p-10" :style="'background-color: ' +randomColor()+'; color: white;'">
              {{ orderItem.product_name }} <br/>
              <span>{{ orderItem.total_count}}</span>
            </div>
          </el-col>
          <!-- <el-col :span="4">
            <div class="schedule_detail_count p-10" style="background-color: #e69d45; color: white;">
              S <br/>
              <span>{{ this.s}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="schedule_detail_count p-10" style="background-color: #d45769; color: white;">
              M <br/>
              <span>{{ this.m}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="schedule_detail_count p-10" style="background-color: #308695; color: white;">
              L <br/>
              <span>{{ this.l}}</span>
            </div>
          </el-col>
          <el-col :span="4">
              <div class="schedule_detail_count p-10" style="background-color: #3b5284; color: white;">
                XL <br/>
                <span>{{ this.xl}}</span>
              </div>
          </el-col>
          <el-col :span="4">
            <div class="schedule_detail_count p-10">
              Саль <br/>
              <span>{{ this.salipetka}}</span>
            </div>
          </el-col> -->
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg m-b-100" justify="center">
      <el-col :span="22" class="home_body m-t-20"  v-loading="loading">
          <!-- <el-select v-model="day_value" placeholder="Гараг сонгох" class="w-100" @change="getTeacherLessonSchedule">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select> -->
  <div>
        <el-card shadow="hover" class="m-t-10 cursor" style="border-radius:20px; line-height:2" v-for="orderItem in this.orderList" :key="orderItem.id">
          <div @click="goToOrderDetail(orderItem)">
          <span class="eelj_text"> {{orderItem.id}} - <a :href="'tel:' +orderItem.customer_phone">{{ orderItem.customer_phone }}</a></span>
          <span class="flr">{{dateFormat(orderItem.created_at)}}</span>
          <br/>
          <span >{{orderItem.detail.length}} бүтээгдэхүүн</span>
          <!-- <el-button v-if="scheduleItem.students.length > 0" class="flr transfer_btn" size="mini" @click="goToScheduleTransportPage(scheduleItem)">Хуваарь шилжүүлэх</el-button> -->
          <span>
               <el-tag type="info" class="flr" v-if="orderItem.order_status === 40">Шинэ
              </el-tag>
               <el-tag type="danger" class="flr" v-if="orderItem.order_status === 10">Цуцлагдсан
              </el-tag>
              <el-tag type="warning" class="flr" v-else-if="orderItem.order_status === 60">Жолоочид хуваарилсан
              </el-tag>
              <el-tag type="primary" class="flr" v-else-if="orderItem.order_status === 70">Хүргэлтэнд гарсан
              </el-tag>
               <el-tag type="success" class="flr" v-else-if="orderItem.order_status === 100">Амжилттай
              </el-tag>
            </span>
                    <br>
                    <span v-if="orderItem.address">{{orderItem.address.district}} дүүрэг - {{orderItem.address.horoo}} - {{orderItem.address.bairname}} - {{orderItem.address.bairnote}} <br /></span>
          <!-- <el-button class="flr m-b-20 m-t-20 detail_text" size="mini"    type="info">Дэлгэрэнгүй</el-button> -->
          </div>
        </el-card>
          <el-card shadow="hover" class="m-t-10 cursor" style="border-radius:20px; line-height:2" v-for="orderItem, index in this.beforeOrder" :key="index">
          <div @click="goToOrderDetail(orderItem)">
          <span class="eelj_text"> {{orderItem.id}} - <a :href="'tel:' +orderItem.customer_phone">{{ orderItem.customer_phone }}</a></span>
          <span class="flr">{{dateFormat(orderItem.created_at)}}</span>
          <br/>
          <span >{{orderItem.detail.length}} бүтээгдэхүүн</span>
          <!-- <el-button v-if="scheduleItem.students.length > 0" class="flr transfer_btn" size="mini" @click="goToScheduleTransportPage(scheduleItem)">Хуваарь шилжүүлэх</el-button> -->
          <span>
               <el-tag type="info" class="flr" v-if="orderItem.order_status === 40">Шинэ
              </el-tag>
               <el-tag type="danger" class="flr" v-if="orderItem.order_status === 10">Цуцлагдсан
              </el-tag>
              <el-tag type="warning" class="flr" v-else-if="orderItem.order_status === 60">Жолоочид хуваарилсан
              </el-tag>
              <el-tag type="primary" class="flr" v-else-if="orderItem.order_status === 70">Хүргэлтэнд гарсан
              </el-tag>
               <el-tag type="success" class="flr" v-else-if="orderItem.order_status === 100">Амжилттай
              </el-tag>
            </span>
                    <br>
                    <span v-if="orderItem.address">{{orderItem.address.district}} дүүрэг - {{orderItem.address.horoo}} - {{orderItem.address.bairname}} - {{orderItem.address.bairnote}} <br /></span>
          <!-- <el-button class="flr m-b-20 m-t-20 detail_text" size="mini"    type="info">Дэлгэрэнгүй</el-button> -->
          </div>
        </el-card>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import services from '@/helpers/services.js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  computed: {
    ...mapGetters({
      orderList: 'getterOrderList',
      beforeOrder: 'getterBeforeOrderList',
      user: 'userGetter'
    })
  },
  data () {
    return {
      loading: true,
      token: JSON.parse(localStorage.getItem('idToken')),
      beforeOrderList: [],
      s: 0,
      m: 0,
      l: 0,
      xl: 0,
      salipetka: 0,
      allDetail: []
      // user: JSON.parse(localStorage.getItem('user'))
    }
  },
  created () {
    console.log(window.CSRF_TOKEN = '{{ csrf_token() }}', this.orderList)
    this.getOrderList()
  },
  methods: {
    randomColor () {
      return '#' + ((1 << 24) * Math.random() | 0).toString(16)
    },
    groupByProductName () {
      return this.allDetail.reduce((acc, product) => {
        // eslint-disable-next-line
        const { product_name, item_count } = product
        // eslint-disable-next-line
        if (!acc[product_name]) {
          acc[product_name] = { product_name, total_count: 0 }
        }
        // eslint-disable-next-line
        acc[product_name].total_count += item_count
        return acc
      }, {})
    },
    checkSuccessOrderCount () {
      let count = 0
      this.orderList.forEach(element => {
        if (element.order_status === 100) {
          count = count + 1
        }
      })
      this.beforeOrderList.forEach(element => {
        if (element.order_status === 100) {
          count = count + 1
        }
      })
      return count
    },
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    },
    dateFormat (data) {
      return data
    },
    getOrderList () {
      this.loading = true
      const payload = {
        start_date: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(8, 'hour'),
        end_date: moment().add(1, 'day')
      }
      const emptyArray = []
      services.getOrderList(payload)
        .then(response => {
          this.$store.dispatch('addOrderData', response.data)
          this.$store.dispatch('beforeOrderData', response.before_orders)
          this.loading = false
          response.data.forEach(element => {
            element.detail.forEach(e => {
              emptyArray.push(e)
            })
          })
          response.before_orders.forEach(element => {
            element.detail.forEach(e => {
              emptyArray.push(e)
            })
          })
          this.allDetail = emptyArray
        }).catch((err) => {
          console.log(err)
        })
    },
    goToOrderDetail (data) {
      this.$store.dispatch('addOrderDetail', data)
      this.$router.push({ name: 'orderDetail', params: { passData: data } })
    },
    goToScheduleTransportPage (schedule) {
      // this.$router.push({ name: 'ScheduleTransform', params: { selectedSchedule: schedule } })
    }
  }
}

</script>

<style>
 .el-header {
    background-color: white;
    color: #5EC7CA;
    font-weight: bolder;
    font-size: 20px;
    line-height: 60px;
    padding: 0px;
    padding-right: 40px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
  }

</style>
