<template>
  <el-row class="login">
    <el-col class="bg-purple-light" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <div>
        <div class="login_input">
          <el-row>
            <el-col>
              <h1 class="title3">Жолоочийн систем</h1><br>
              <h3 class="title4">Тавтай морилно уу</h3>
            </el-col>
          </el-row>
          <el-form class="m-t-40" :model="loginForm" label-position="top" ref="dynamicValidateForm" label-width="120px">
            <el-form-item prop="phone" :rules="[
            { required: true, message: 'Бүртгэлийн дугаар хоосон байна.', trigger: 'blur' }]">
              <el-input v-model="loginForm.phone" clearable placeholder="Бүртгэлийн дугаар">
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="button1" type="primary" @click="submitForm('dynamicValidateForm')">Нэвтрэх</el-button>
            </el-form-item>
            <!-- <div>
              <el-button class="forget_pass_link" type="text" @click="forgetpass()">Нууц үгээ мартсан </el-button>
            </div> -->
          </el-form>
        </div>
      </div>
    </el-col>
    <loader-item :isLoader='this.$root.fullscreenLoading'></loader-item>
  </el-row>
</template>

<script>
import LoaderItem from '@/components/loader/loader-item.vue'
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom.js'
import Logo from '@/assets/dma_logo.png'
export default {
  components: {
    LoaderItem
  },
  data () {
    return {
      loginForm: {
        phone: '',
        password: '12345'
      },
      url: Logo
    }
  },
  methods: {
    forgetpass () {
      this.$router.push({
        name: 'resetPassword'
      })
    },
    adduser () {
      this.$router.push({
        name: 'regUser'
      })
    },
    submitForm (formName) {
      console.log(this)
      this.$root.fullscreenLoading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch('signin', this.loginForm).then(response => {
            if (response.success.token) {
              this.$store.dispatch('authUserLoginPage', response.success)
              this.$store.dispatch('userAdd', response.user)
              // this.padd()
              // window.location.href = '/'
              this.$router.push({
                name: 'Home'
              })
            } else if (response.status === 401) {
              notification('login_user_error')
            }
            this.$root.fullscreenLoading = false
          }, error => {
            console.log(error)
            this.$root.fullscreenLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    padd () {
      const jishee = {
        uuid: JSON.parse(localStorage.getItem('userId'))
      }
      services.getUserById(jishee)
        .then((response) => {
          console.log(response)
          // this.$root.logged_in_user_name = response.result.first_name
          this.$store.dispatch('userAdd', response.result.first_name)
        })
    }
  }
}

</script>
