<template>
  <div class="page-wrapper chiller-theme">
      <main class="page-content">
        <router-view />
      </main>
          <el-row class="layout_footer" justify="center">
            <el-col :span="8" class="p-10">
            <a href="/" class="cursor_pointer">
             <i class="el-icon-s-order"></i><br />
              Захиалга
            </a>
          </el-col>
          <el-col :span="8" class="p-0">
            <el-badge :value="this.$root.rootNotificationCount" class="item notif_count_style">
              <a href="/notifications" class="cursor_pointer">
            <i class="el-icon-message-solid m-r-4" @click="goNotificationsPage()"></i><br/>
              Мэдэгдэл
              </a>
            </el-badge>
          </el-col>
          <el-col :span="8" class="p-10 bottom-navbar">
            <a href="/profile" class="cursor_pointer">
             <i class="el-icon-s-custom"></i><br />
              Фрофайл
            </a>
          </el-col>
        </el-row>
    <loader-item :isLoader='this.$root.fullscreenLoading'></loader-item>
  </div>
</template>
<script>
// import { SidebarMenu } from 'vue-sidebar-menu'
import LoaderItem from '@/components/loader/loader-item.vue'
// import services from '@/helpers/services.js'

// accessTokenaccessToken

export default {
  components: {
    LoaderItem
  },
  data () {
    return {
      containerCollapsed: 'toggled',
      tabledata: []
      // user: JSON.parse(localStorage.getItem('user'))

    }
  },
  created () {
    // services.getNotificationList().then((response) => {
    //   this.$root.rootNotificationCount = response.data.length
    // })
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    },
    goNotificationsPage () {
      this.$router.push('/notifications')
    }
  }
}

</script>
<style scoped>

  .el-aside {
    color: #333;
  }

  .item {
    margin-top: 12px;
    margin-right: 20px;
  }

</style>
